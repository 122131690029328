/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Hind Madurai";
  src: local("Hind Madurai"), url(./assets/fonts/Hind_Madurai/HindMadurai-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Hind Madurai";
  src: local("Hind Madurai"), url(./assets/fonts/Hind_Madurai/HindMadurai-Bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"), url(./assets/fonts/Merriweather/Merriweather-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"), url(./assets/fonts/Merriweather/Merriweather-Bold.ttf) format("truetype");
  font-weight: bold;
}
html, body { height: 100%; }
body { margin: 0; font-family: "Hind Madurai", "Helvetica Neue", sans-serif; }
a { cursor: pointer; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
