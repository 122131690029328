@media only screen
        and (max-width: 2560px)
        and (max-height: 1440px)
        and (min-width: 1981px)
        and (min-height: 1200px) {
    h1{
        font-size: 2.75em;
    }
    .col-footer-label{
        font-size: 2.75em;
    }

    .col-footer-content p,
    #enzyklopedia_view p,
    #imprint_view p,
    #tradition-tab-content p,
    #howto_view p,
    #tradition_view p,
    #tradition-tab-content p,
    #welcome-data  p,
    #contact_page p {
        font-family: Merriweather,Palatino,Georgia,serif;
        font-size: 1.2em;
        line-height: 1.87em;
        margin: 0 0 20px;
        letter-spacing: .2px;
    }
}

