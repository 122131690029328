// TABLET || PHONE PORTRAIT
@media only screen and (max-width: 600px) and (max-height: 1200px),
only screen and (min-width: 601px) and (max-width: 800px) and (max-height: 1200px),
only screen and (min-width: 701px)  and (max-width: 900px) and (min-height: 900px) and (max-height: 1300px),
only screen and (min-width: 901px)  and (max-width: 1024px) and (min-height: 1024px) and (max-height: 1300px)
{
  #expertsearch-tab-content, .expertsearch
  {
    display: none;
  }
  html, body
  {
    overflow: scroll;
  }
  html::-webkit-scrollbar,
  body::-webkit-scrollbar
  {
    display: none;
  }
  h2
  {
    font-size: 2.3em;
    line-height: 1em;
  }
  #welcome_view,
  #detail-view,
  #main_view,
  #search-result-view
  {
    all: unset;
    display: block;
    position: relative;
    width: 100vw;
  }

  #welcome_view::-webkit-scrollbar,
  #detail-view::-webkit-scrollbar,
  #main_view::-webkit-scrollbar,
  #search-result-view::-webkit-scrollbar,
  #search-view::-webkit-scrollbar,
  #person-data::-webkit-scrollbar,
  {
    display: none;
  }
  .welcome-data
  {
    all: unset;
  }
  .welcome-data,
  .detail-data,
  #search-view,
  #search-data,
  #search-result-list,
  {
    display: block;
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding: 32px;
    overflow: auto;
  }
  #search-data,
  #person-data,
  #person-content,
  #detail-view
  {
    position: relative;
    width: 80%;
    display: block;
    left: -8px;
  }
  #person-content
  {
    top: 0;
  }
  #search-data #tab-container
  {
    all: unset;
    position: relative;
    width: 96%;
    display: block;
    left: -23px;
    margin-bottom: 32px;
  }
  .search_clip input
  {
    width: 100%;
  }
  .search_input
  {
    width: 100%;
  }
  #search-input,
  .search_clip
  {
    width: 70%;
  }
  #content-tabs
  {
    all: unset;
  }
  #person-content #content-tabs
  {
    display: block;
    width: 80%;
  }
  .dict-index
  {
    position: absolute;
    width: 1.5rem;
    height: 80vh;
    top: 32px;
    right: 0px;
  }
  #portrait-box,
  #portrait,
  .portrait
  {
    width: 280px;
    height: 365px;
    float: none;
    margin: 0;
    padding: 0;
  }
  .tab-content
  {
    width: 80%;
  }
  #expertsearch-tab-content, .expertsearch
  {
    display: none;
  }
  #tab-container
  {
    display: inline;
    left: -32px;
  }
  #search-result-container
  {
    width: 96vw;
  }
  #tab-container .tab-button:not(:first-of-type)
  {
    border-left: none;
  }
  #person-content,
  #person-content #content-tabs
  {
    width: 100%;
  }
  #person-content video
  {
    width: 320px;
  }
  #person-data #tab-container
  {
    all:unset;
  }
  #person-data #tab-container .link-box
  {
    all:unset;
    margin: 12px;
  }
  #person-data #tab-container
  {
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    right: 2.5rem;
    top: 2rem;
    width: 100vh;
    height: 56px;
    float: left;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform-origin: left bottom 0;
    -webkit-transform-origin: right top 0;
    text-align: center;
    -ms-transform-origin: left bottom 0;
    align-self: center;
  }
}
