// mobile landscape
@media only screen and (max-width: 798px) and (max-height: 400px)
{
  html, body
  {
    overflow: scroll;
  }
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none;
  }
  #expertsearch-tab-content, .expertsearch
  {
    display: none;
  }
  h2
  {
    font-size: 2.3em;
    line-height: 1em;
  }
  #welcome_view,
  #detail-view,
  #main_view,
  #search-result-view
  {
    all: unset;
    display: block;
    position: relative;
    width: 100vw;
  }

  #welcome_view::-webkit-scrollbar,
  #detail-view::-webkit-scrollbar,
  #main_view::-webkit-scrollbar,
  #search-result-view::-webkit-scrollbar,
  #search-view::-webkit-scrollbar,
  #person-data::-webkit-scrollbar,
  {
    display: none;
  }
  .welcome-data
  {
    all: unset;
  }
  .welcome-data,
  .detail-data,
  #search-view,
  #search-data,
  #search-result-list,
  {
    display: block;
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding: 32px;
    overflow: auto;
  }
  #search-data,
  #person-data,
  #person-content
  {
    position: relative;
    width: 90%;
    display: block;
  }
  #tab-container
  {
    all: unset;
    position: relative;
    width: 96%;
    display: block;
    left: -23px;
    margin-bottom: 32px;
  }
  #content-tabs
  {
    all: unset;
  }
  .search_clip input
  {
    width: 100%;
  }
  .search_input
  {
    width: 100%;
  }
}
