html, body
{
    overflow: hidden;
    font-family: 'Merriweather', serif;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    -ms-overflow-x: hidden;
    -ms-overflow-y: hidden;
    margin: 0;
    padding: 0;
}
body
{
    min-width: 100%;
}
h1, h2, h3, h4, h5, h6
{
    font-family: 'Hind Madurai', sans-serif;
}

h1, h2, h3, h4
{
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;
}
h2
{
    font-size: 3.5em;
    line-height: 1em;
}
h3
{
    display: inline-block;
    font-size: 1.2em;
    font-weight: 900;
}
h5
{
    font-size: 1.125em;
    margin: 0 0 0.675em 0;
}
h6
{
    font-size: 1em;
    margin: 0 0 0.5em 0;
}
a, button
{
    font-family: 'Hind Madurai', sans-serif;
}
a:active span,
a:focus span
{
    position: relative;
    top:0;
    left:0;
    right: 20px;
}
button:focus
{
    outline: none;
}
input, textarea
{
    font-size: 100%;
}
tr, td
{
    border: 1px solid transparent;
}
.label
{
    font-weight: 600;
    margin-top: 1em;
}
span
{
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*margin: 0 20px 0 0;*/
    /*line-height: 1.75;*/
}
span:focus
{
    outline: none;
    font-weight: 900;
}
em
{
    font-weight: 900;
    font-style: normal;
}
video
{
    display: block;
}
::-webkit-scrollbar
{
    display: none;
}

/******** Base Page View Container *********/
:focus
{
    outline: -webkit-focus-ring-color none 0px;
}
#main_view
{
    position: absolute;
    overflow: auto;
    height: calc(100% - 100px);
    width: 100%;
}
#start_view
{
    position: relative;
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    left: 50px;
    right: 50px;
    background: #8c0651;
    background-size: cover;
    background-position-y: 50%;
    overflow: hidden;
}
#tradition_view, #howto_view, #imprint_view, #terms_view
{
    width: 800px;
    height: calc(100% - 150px);
    margin: 0 auto;
    padding-bottom: 150px;
    overflow: auto;
}
#terms_view::-webkit-scrollbar,
#tradition_view::-webkit-scrollbar,
#howto_view::-webkit-scrollbar,
#imprint_view::-webkit-scrollbar
{
    display: none;
}
/*********** Passepartout ******************/
#left-sidebar
{
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
}
#right-sidebar
{
    position: absolute;
    width: 50px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 99;
    margin-bottom: 42px;
}
#topbar
{
    position: relative;
    height:50px;
    z-index: 99;
}
#footer
{
    position: absolute;
    width:100%;
    height: 50px;
    bottom: 0;
    z-index: 99;
}
#footer-nav-right
{
    position: absolute;
    right: 50px;
    bottom: 0;
    text-transform: uppercase;
}
#footer-nav-right a
{
    position: relative;
    top: 0;
    height: 50px;
    display: table-cell;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 20px 0 0;
    left: 20px;
}
#footer-nav-left
{
    position: absolute;
    left: 50px;
    bottom: 0;
    text-transform: uppercase;
}
#footer-nav-left .brand-link
{
    position: relative;
    height: 50px;
    display: table-cell;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 20px 0 0;
    left: 0px;
    font-family: "Hind Madurai", sans-serif;
    font-size: 1em;
}
#branding,
.brand-link
{
    height:50px;
    font-weight: 900;
    font-size:1rem;
    text-decoration:none;
    letter-spacing: 0.2px;
}
a:hover.brand-link
{
    text-shadow: none !important;
}
.brand-link-magenta
{
    color: #ec008b;
}
#person-data a.brand-link-magenta,
#person-data a.brand-link-magenta:hover
{
  position: relative;
  left: -6px;
  color: #ec008b;
  text-shadow: none !important;
}
.detail-data a.brand-link-magenta:hover
{
  position: relative;
  color: #ec008b;
  text-shadow: none !important;
}
.menu-hidden
{
    display: none;
    opacity:0;
}
.menu-visible
{
    display: initial;
    opacity:1;
}
.link-box-left
{
    height: 45px;
    float: left;
    padding: 5px 10px;
    margin: 0 5px;
}
.link-box-right
{
    height: 45px;
    float: right;
    right: -5px;
    padding: 5px 10px 0 0;
    margin-left: 0px;
}
.link-box-bottom
{
    position: relative;
    height: 50px;
    float: left;
    top: 0;
    left: 0;
}
.index-separator
{
    font-size: 0.8em;
    line-height: 1.75;
}
#person-data .index-separator
{
    float: left;
    padding: 0;
    margin: 0.4em 0 0 0;
}
#tab-container .link-box
{
    position:relative;
    float:left;
    top:0;
    padding: 0 15px;
    margin: 0 0 0 8px;
}
#tab-container .tab-button:not(:first-of-type)
{
  position: relative;
  display: inline-block;
  padding: 0;
  border-left: 1px solid #000;
}
#tab-container .link-box a
{
    padding: 0 0px;
}
#person-data #tab-container .link-box
{
    margin-top: 0px;
}
#person-content
{
    position: relative;
    top:2.7em;
}
#contact-button
{
    position: relative;
    font-family: 'Hind Madurai', sans-serif;
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    -ms-transform: rotate(270deg);
    align-self: center;
    z-index: 9999;
    top: 22px;;
}
#contact-button-box
{
    position: relative;
    left: 15px;
    width: 100vh;
    float: left;
    top: calc(100vh);
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform-origin: left bottom 0;
    -webkit-transform-origin: left bottom 0;
    text-align: center;
    -ms-transform-origin: left bottom 0;
    align-self: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
{
    #contact-button
    {
        position: relative;
        left: -50px;
        float: left;
        -ms-transform: rotate(0deg);
        font-family: 'Hind Madurai', sans-serif;
        font-size: 1em;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
    }
    #contact-button-box
    {
        top: calc(50% - 1.5em);
    }
}
.r-btn
{
  cursor: pointer;
}
#de-button
{
    right: calc(50vh - 7em + 48px);
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    float: right;
    margin-top: calc(25px - 0.675em);
    position: relative;
}
#en-button
{
    position: relative;
    font-family: 'Hind Madurai', sans-serif;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    float: right;
    z-index: 4;
}

.right-sidebar-button
{
    position: relative;
    width: 50vh;
    height: 50px;
    right: calc(25vh - 25px);
    float: left;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
}
#en-button-container
{
    top: 15px;
    right: -50px;
    position: relative;
}
#de-button-box
{
    position: relative;
    top: calc(25vh - 25px);
}

#en-button-box
{
    top: 75vh;
}
.active-lang-button
{
    color: rgba(0,0,0, 0.7);
    text-shadow: 1px 0 0 rgba(0,0,0,0.7);
}

.active-lang-button a
{
    cursor: default;
    color: rgba(0,0,0, 1);
    text-shadow: 1px 0 0 rgba(0,0,0,1);
}

/********* Search View **************/
#search_view,
#ezyklopedia_view,
#welcome_view
{
    overflow-x: hidden;
    overflow-y: hidden;
}
#ezyklopedia_view
{
    margin-bottom: 100px;
    padding-bottom: 100px;
}
#search-result-view
{
    overflow: auto;
    height: 100%;
}
#tab-container
{
    position: relative;
    width: 815px;
    left: -26px;
    margin-bottom: 3.4375em;
}
#search-data #tab-container
{
    left: -20px;
}
#search-data #tab-container .index-separator
{
    float: left;
    margin: 0;
    padding: 0px;
    top: 0.4em;
    position: relative;
}
#content-tabs
{
    position: relative;
    height: 100%;
    min-height:100vh;
    width: 715px;
    top: -55px;
    font-family: 'Hind Madurai', sans-serif;
    margin-bottom: 100px;
    padding-bottom: 100px;
}
#content-tabs div p:first-child
{
    position: relative;
    top: 0;
}
#content-tabs div p
{
    margin-bottom: 0;
}
#search-data,
#enzyklopedia-data,
.welcome-data
{
    width: 715px;
    margin:10px auto;
    left: 42px;
    right: 42px;
    padding-bottom: 100px;
    margin-bottom: 100px;
}
#search-result-container
{
    position: relative;
    width: 715px;
    height: 100%;
    margin: 10px auto;
}
#search-result-list
{
    position: relative;
    width: 715px;
    overflow: auto;
    margin-bottom: 100px;
    padding-bottom: 100px;
}
#search-result-list ul a:hover
{
    text-shadow: none;
}
#search-container
{
    width: 65%;
    position: relative;
    overflow: auto;
    margin-top: 5px;
}
#search-input
{
    position: relative;
    top: 25px;
    left: 0;
    color: #fff;
    width: 60%;
}
#first-search-input
{
    color:#000;
}
.search_clip
{
    position: relative;
    width: 400px;
}
.search_clip input
{
    width: 567px;
    height: 39px;
    font-family: 'Hind Madurai', sans-serif;
    font-weight: bold;
    padding: 0 0 0 1.3em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    float: left;
}
#search md-autocomplete-wrap
{
    height: 56px;
}
#searchOpBool.tab
{
    float: left;
    margin:10px 10px 10px 0px;
}
.macfox #searchOpBool.tab
{
    float: left;
    margin:10px 10px 10px -4px;
}
input::placeholder, input::-moz-placeholder,
textarea::placeholder, textarea::-moz-placeholder,
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder
{
    font-family: "Hind Madurai", sans-serif;
    font-weight:600;
    text-transform: uppercase;
    color: #000;
}

textarea{
    -moz-border-bottom-colors: #000;
}

.search_clip input::placeholder,
.search_clip input::-moz-placeholder{
    color: #000;
}

.search-btn {
    position: relative;
    width: 42px;
    height:41px;
    color: #fff;
    background: #181818;
    text-align: right;
    font-size: 1.2em;
    float: left;
    top: 0px;
    left: 0px;
    border: solid 1px #000;
}

.search-btn-label{
    position: relative;
    float: left;
    top:4px;left: 8px
}

.search-btn-icon {
    width:24px;
    height:24px;
    margin:8px 8px 0 0;
}

.search_clip .icon {
    position: absolute;
    top: 11px;
    color: #fff;
}
.checkbox-label
{
    position: relative;
    left: 27px;
    margin-bottom: 6px;
    letter-spacing: 0.4px;
    text-indent: -27px;
}
.checkbox-label input
{
    margin-right: 8px;
}
.radio_label
{
    position: relative;
    top: -1px;
    left: 4px;
}
#search-result-list h1
{
     font-size: 3.4375em;
}
#search-result-list h2{
    /*font-size: 2.6em;*/
    font-size: 1.4375em;
}
#search-result-list ul
{
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    left: -20px;
    list-style-type: none;
}

.input-grid {
    position: relative;
    float: left;
    height: 100%;
    top: 3.4375em;
    left: -15px;
    display: block;
    margin-bottom: 100px;
    padding-bottom: 100px;
}

/** START PERSON INDEX ***/
.index-link
{
    text-decoration: none;
    padding: 0 0.5em 0 0.5em;
    font-size: 1em;
}
.index-link.active:hover
{
 text-shadow: none;
}
.index-link.inactive:hover
{
    text-shadow: none;
    cursor: not-allowed;
}
.index-link.active
{
    font-weight: bold;
}
.persondict-index-key
{
    font-family: "Hind Madurai", sans-serif;
    font-size: 1em;
    line-height: 1.9375em;
}
#index-component-list
{
    position:relative;
    max-height:1000vh;
    height:100%;
    left: -20px;
    display:block;
    overflow:auto;
    margin-bottom:100px;
    padding-bottom:100px;
    padding-left: 20px;
}
.persondict-index-entry
{
    margin: 6px 0;
    margin-bottom: 1.3rem;
}
.persondict-index-entry-label,
.persondict-index-entry-text
{
    position:relative;
    font-family: Merriweather, Palatino, Georgia, serif;
    font-size: 1em;
    line-height: 1.9375em;
    font-weight: normal;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 20px 0 0;
}
.result-property-label{
    color: #ec008b;
}
.result-property-label, .result-property-value
{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 20px 0 0;
    line-height: 1.75;
}
.persondict-index-entry-label
{
    left: -18px;
}
.persondict-index-entry-text
{
    left: -36px;
}
.persondict-index-entry a
{
    font-family: "Hind Madurai", sans-serif;
    font-size: 1.063em;
    line-height: 1.75em;
    font-weight:bold;
    letter-spacing: 0.2px;
}
.persondict-index-entry a:hover
{
    text-shadow: 1px 0 0 rgba(0,0,0,0) !important;
}
.active-index-link
{
    color: #aaa !important;
    border: 1px solid #007be0;
    border-radius:25px;
}

.active-index-link a{
    text-decoration: underline !important;
    cursor: default;
}
.active-index-link.highlight-dark-color
{
    background-color: #007be0 !important;
}
.ezyclopedia-link
{
    margin-bottom: 0;
    font-size: 1em;
}
a:hover .ezyclopedia-link
{
    text-shadow: none !important;
}
/** END PERSON INDEX ***/

/**** Tradition ****/

#tradition-tab-content{
    padding-bottom: 100px;
    margin-bottom: 100px;
}

/***** Person Page *********/
#person_view
{
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 48px;
}
#person-data
{
    position: relative;
    width: 815px;
    height: 100%;
    margin: 0 auto 300px auto;
}
#person-content span
{
    margin: 0;
}
#person-headline
{
    position: relative;
    width: 100%;
    top: 2em;
    float: left;
    margin-bottom: 1.75em;
}
#add-person
{
    position: relative;
    width: 42px;
    height: 42px;
    top: 0.8em;
    right: 0;
    float: right;
}
.property-list
{
    position: relative;
    list-style-type: none;
    margin-left: -40px;
    margin-top: 0;
}
.property-list:last-child
{
    margin-bottom: 1em;
}
.tab
{
    font-family: 'Hind Madurai', sans-serif;
    font-size: 1.0625em;;
    line-height: 1.85em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    margin: 0 0 0 0;
    padding: 0;
}
.tab:focus
{
    margin: 0;
    padding:0;
}
.selected-tab
{
    text-shadow: 1px 0 0 black;
    text-decoration: underline;
}

.selected-tab .tab {
  text-decoration: underline;
}

.separator-left{
    border-left: 1px solid #000;
    padding: 0 0 0 30px;
}

.tab-button
{
    font-family: 'Hind Madurai', sans-serif;
    font-size: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    padding:0;
    background-color: transparent;
    cursor: pointer;
}
#portrait
{
    float: right;
    width: 280px;
    height: 365px;
    padding: 0 0 0 2em;
}
.tab-content
{
    margin-bottom: 4em;
    height: 100%;
    font-family: Merriweather, Palatino, Georgia, serif;
    font-size: 1em;
    line-height: 1.9375em;
    top: 3.4275em;
    position: relative;
}
.inactive
{
    color: #999;
    font-weight: 600;
}
legend
{
    font-weight: 900;
    text-transform: uppercase;
}
.category-checkbox-legend
{
    position: relative;
    float: left;
    margin-bottom: 1em;
}
.category-checkbox-input
{
    float: left;
    left: -5px;
}
.filter fieldset input
{
    display: inline-block;
}
.column-1
{
    float: left;
    width: 33%;
    display: inline;
    padding-right: 14px;
}
.column-2
{
    float: left;
    width: 65%;
    display: inline;
}
.grid:after
{
    content: "";
    display: table;
    clear: both;
}
fieldset
{
    font-family: 'Hind Madurai', sans-serif;
    border: none;
}
fieldset label
{
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
fieldset label input
{
    width: 10px;
    display: inline-block;
    vertical-align: top;
}
.clear
{
    clear: both;
}

* {
    scrollbar-width: thin;
    scrollbar-width: none;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar
{
    width: 11px;
}
*::-webkit-scrollbar-track
{
    background: white;
}
*::-webkit-scrollbar-thumb
{
    background-color: black;
}

/************** CONTACT US ********************/
.button_label
{
    display: inline-block;
    line-height: 2.6rem;
    margin: 0;
    padding: 0;
}

.tooltip
{
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext
{
    visibility: hidden;
    width: 100px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 0px;
    font-size: 0.8em;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: -4px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext
{
    visibility: visible;
}

/**************************************************************/

/********** BASKET ********/

#button-container
{
    position: relative;
    left: -10px;
}

#button-container .link-box
{
    padding-left: 10px;
    padding-right: 10px;
}

#basket-data
{
    position: relative;
    width: 715px;
    height: 100%;
    margin: 0 auto;
}

.basket-entry
{
    width: 100%;
    height: 3rem;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 1.3rem;
    padding-bottom: 10px;
}

.basket-entry a
{
    text-decoration: none;
}

.basket-entry a:hover
{
    font-weight: normal;
    text-shadow: 1px 0 0 rgba(0,0,0,0) !important;
}

.basket-entry-title
{
    font-family: "Hind Madurai", sans-serif;
    font-weight: bold;
    font-size: 1.0625em;
    line-height: 1.75em;
    margin:0;
}

.basket-entry-label
{
    height:100%;
    margin-left:23px;
    width: 92%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-family: Merriweather, Palatino, Georgia, serif;
    font-size: 1em;
    line-height: 1.9375em;
    letter-spacing: 0.2px;
}

input[type='checkbox']
{
    position: relative;
    top: 1px;
    transform: scale(1.2);
}

input[type='radio']
{
    margin:0 3px 0 3px;
}

.safari input[type='checkbox']
{
    position: relative;
    top: -1px;
    padding: 10px;
    transform: scale(1);
}

.safari input[type='radio']
{
    position: relative;
    top: -1px;
    left:3px;
}

.macfox input[type='radio']
{
    position: relative;
    top: -2px;
    left:3px;
}

.safari .radio_label
{
    position: relative;
    left: 8px;
}

.macfox .radio_label
{
    position: relative;
    left: 8px;
}

#enzyklopedia_view p,
#imprint_view p,
#terms_view p,
#tradition-tab-content p,
#howto_view p,
#tradition_view p,
#tradition-tab-content p,
#welcome-data  p,
#contact_page p
{
    font-family: Merriweather, Palatino, Georgia, serif;
    font-size: 1em;
    line-height: 1.9375em;
    margin: 0px 0px 20px 0px;
    letter-spacing: 0.2px;
}

#imprint-data h4,
#imprint-data h3,
#terms-view h3,
#howto_view h3
{
    padding: 1em 0 0 0;
}

.no-list-style li
{
    margin: 0;
}

.carry-on-list li:before,
#imprint_view li:before,
#terms_view li:before
{
    position: relative;
    top: -2px;
    content:"•";
    display: inline-block;
    vertical-align: middle;
    font-size:3.4375em;
    line-height: 0.55em;
    padding-right: 7px;
}

.carry-on-list li
{
    list-style-position: inside;
    padding: 10px 0 10px 20px;
    text-indent: -1em;
}

.carry-on-list li:after,
#imprint_view li:after,
#terms_view li:after
{
    font-size: 1em;
}

.option-greyscale #intro_view
{
    color: #fff;
}

.logo-ezjm
{
     background: url('../images/logo/hmtmh_ezjm-4c.ai.png') no-repeat;
     width: 390px;
     height: 100px;
}
.logo-stiftung
{
    background: url('../images/logo/Volkswagenstiftung.png') no-repeat;
    width: 800px;
    height:200px;
}
.theme-color-light .logo-sharing-heritage
{
    background: url('../images/logo/Sharing_Heritage_Logo_Deutsch.png') no-repeat;
    width: 800px;
    height:565px;
}
.theme-color-dark .logo-sharing-heritage
{
    background: url('../images/logo/SH_A1_wm_Projekte_n_DEU.png') no-repeat;
    width: 800px;
    height:565px;
}
.theme-color-dark .contrast
{
    filter: grayscale(100%);
}
.theme-color-dark .logo-stiftung,
.theme-color-dark .logo-ezjm
{
    filter:  invert(1) brightness(1) contrast(100%);
}

/**** MAP *****/
#map-page
{
    height: 100%;
    width: calc(100% - 50px);
}
#map
{
    height: calc(100% - 220px);
    width: calc(100% - 50px);
    left: 50px;
}
#map-footer
{
    position: relative;
    height: 180px;
    left: 50px;
    top: 40px;
    right: 50px;
    margin-right: 50px;
    font-family: "Hind Madurai", sans-serif;
    overflow: hidden;
}
/*** MAP SearchBox**/
.handlebar
{
    position: absolute;
    width: 24px;
    height: 48px;
    top: 8px;
    left: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
}
.handlebar:hover
{
    cursor: pointer;
}
.theme-color-dark .handlebar
{
    background-color: rgba(255, 255, 255, 0.87);
}

.theme-color-dark .handlebar .search-btn-icon path
{
    fill: #000 !important;
}
.handlebar .search-btn-icon
{
    position: relative;
    top: calc(50% - 20px);
}
.handlebar-open {}
.handlebar-open .search-btn-icon
{
    transform: rotate(180deg);
}
.handlebar-close
{
    left: 100%;
    /*border: 4px solid #ededed;*/
    box-shadow: 4px 5px 10px 0px #5a5a5a;
}
.handlebar-close .search-btn-icon
{
    transform: none;
}
#search-box-map
{
    position: absolute;
    width: 367px;
    height: 64px;
    top: 0px;
    left: 50px;
    z-index: 9;
}
.md-standard-list-container.md-autocomplete-suggestions-container.md-whiteframe-z1.md-default-theme
{
    width: 359px;
}

#search-input-wrapper-map
{
    position: absolute;
    height: 56px;
    width: calc(100% - 8px);
    left:0px;
    float: none;
    z-index:9;
    background-color: #fff;
    border: 4px solid #ededed;
    box-shadow: 2px 5px 10px 0px #5a5a5a;
}
.theme-color-dark #search-input-wrapper-map
{
    background-color: #000;
    color: #fff;
}
#search-input-wrapper-map.search_clip input
{
    position: relative;
    top: 8px;
    font-size: 1.4rem;
    border: none;
}
#search-input-wrapper-map .search-btn-icon
{
    float: right;
}
#slider-range
{
    width: 100%;
    left: 0;
    top:0;
}
.md-datepicker-calendar
{
    margin-top: -158px;
}
.theme-color-dark .md-datepicker-input
{
    color: #fff;
}
.theme-color-dark .md-datepicker-button md-icon
{
    fill: #fff;
}
.theme-color-dark .md-datepicker-triangle-button .md-datepicker-expand-triangle
{
    border-top-color: #fff;
}
.date-range-arrow
{
    position:relative;
    width:32px;
    height:32px;
    top:-10px;
    fill:#000;
}
.date-range-arrow:hover
{
    cursor: pointer;
}
.theme-color-dark .md-default-theme .md-calendar, .md-calendar
{
    background: rgb(0,0,0);
    color: rgba(255,255,255,0.87);
}
.theme-color-dark .date-range-arrow
{
    fill:#fff;
}
#min .md-icon-button
{
    margin-left: 8px;
}
#max .md-icon-button
{
    margin-left: 32px;
}
#min .md-icon-button + .md-datepicker-input-container,
#max .md-icon-button + .md-datepicker-input-container
{
    margin-left: 0;
}
.theme-color-dark .md-datepicker-input-container input
{
    border: none;
    border-bottom: 1px solid rgba(255,255,255,0.85);
}
.theme-color-dark .ui-ruler-tick-label,
.theme-color-dark .ui-ruler-tick-inner
{
    color: #fff;
    border-color: #fff !important;
}
.theme-color-dark .ui-rangeSlider .ui-rangeSlider-innerBar
{
    background-color: rgba(255,255,255,0.15);
}
/** md-list **/
.cluster-list
{
    position: relative;
    top: 64px;
    background-color: rgba(255, 255, 255, 0.7);
    min-height: calc(100% - 80px);
}
.theme-color-dark .cluster-list
{
    background-color: rgba(0, 0, 0, 0.7);
}
.theme-color-dark .cluster-list-item
{
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
}
.cluster-list-item.md-clickable:hover
{
    background-color: rgba(228,36,133, .4);
    z-index: 999;
}
.cluster-list-item.md-clickable:hover .cluster-list-item-text-wrapper .cluster-list-item-text
{
    color: #fff !important;
}
.cluster-list-item
{
    background-color: rgba(255, 255, 255, 1);
    padding:0 !important;
}
.cluster-list-item button:hover
{
    background-color: rgba(228,36,133, 0.4) !important;
    color: #fff !important;
}
.cluster-list-item .md-button:not([disabled]):hover
{
    background-color: transparent !important;
}
.cluster-list-item-text-wrapper{}
.cluster-list-item-text
{
    /*color: #000 !important;*/
    font-size: 1.05rem !important;
    font-weight: 600 !important;
    font-family: "Hind Madurai";
}
.theme-color-dark .cluster-list-item-text
{
    color: #fff !important;
}
h3.cluster-list-item-text
{
    /*color: #000 !important;*/
    font-size: 1.05rem !important;
    font-weight: 900 !important;
    font-variant: normal !important;
    font-family: 'Hind Madurai';
    display: block;
    text-transform: unset !important;
}
h4.cluster-list-item-text
{
    /*color: cornsilk !important;*/
    font-size: 1.05rem !important;
    font-weight: 600 !important;
    font-variant: normal !important;
    font-family: "Hind Madurai";
    text-transform: unset !important;
}
.cluster-list-item-divider
{
    /*border-color: darkmagenta !important;*/
}
.md-datepicker-calendar-pane.md-datepicker-pos-adjusted.md-pane-open
{
    z-index: 999;
}

/************* Detail *****************/
.detail-placeholder, .detail-panel
{
    position: absolute;
    width: 928px; /* calc: 939px - 11px ScrollBar */
    height: calc(100% - 220px);
    /*left: calc(35% - 50px);*/
    left: 417px; /* 367 px sidebar + 50px passepartout */
    top: 0;
    background: rgba(255,255,255, 1);
    overflow: hidden;
    z-index:10;
}
.detail-include
{
    display: block;
    height: 100%;
}
#detail-wrapper
{
    position:relative;
    width: 901px;
    height:100%;
    background: rgba(255,255,255, 0.3);
}
.detail-content-wrapper
{
    width: 650px;
    margin-left: 92px;
}
.theme-color-dark #detail-wrapper,
.theme-color-dark .detail-content
{
    color: #fff;
    background: rgba(0,0,0, 0.85);
}
#detail-card
{
    height: calc(100% - 16px);
    background-color: rgba(250,250, 250, 0.9)
}
.content-wrapper
{
    width: 650px
}
.card-content
{
    height:100%;
    /*width: 96%;*/
    margin:0;
    padding:0 0 0 32px;
    box-shadow: 0px -1px 0px 0px rgba(0,0,0,0.3) inset;
}
/** Grid List **/
#cluster-info-panel
{
  position: absolute;
  width: 367px;
  top: 0px;
  left: 50px;
  height: calc(100% - 220px);
  background: rgba(255,255,255,0.5);
  z-index: 8;
  overflow: auto;
}
.cluster-grid
{
    display: block;
}
.cluster-grid-tile
{
    margin-bottom: 16px;
    padding:16px;
    border:1px solid #ff55ff;
    background-color: cornsilk;
}
md-card-content md-grid-list md-grid-tile md-grid-tile-header,
md-card-content md-grid-list md-grid-tile md-grid-tile-footer
{
    background: transparent !important;
    overflow: auto !important;
    color: #000;
}
/** FORM **/
.panel-wrapper
{
    position: relative;
    width: calc(100% - 100px);
    height: 100%;
    left: 50px;
}
.form-title-description
{
    position: relative;
}
#createCmsContentForm
{
    position: relative;
    left:16px;
}
#edit-panel
{
    margin-bottom: 156px;
}
.panel-card
{
    height: 100%;
    padding-bottom: 24px;
}
.show
{
    display: block;
}
.hide
{
    display: none;
}
#detail-view
{
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
//.detail-data
//{
//  position: relative;
//  width: 815px;
//  height: 100%;
//  margin: 0 auto 300px auto;
//  line-height: 1.9375em;
//}
.dict-index
{
    position:relative;
    height:42px;
    margin: 0 auto 0 -10px;
    padding-left: 0.5em;
}
#portrait-box
{
    float:right;
    height:384px;
}
.theme-color-dark .media-credit
{
    color: rgba(255,255,255,0.75) !important;
}
// mobile landscape
//@media only screen and (max-width: 798px) and (max-height: 400px)
//{
//    html, body
//    {
//        overflow: scroll;
//    }
//    html::-webkit-scrollbar,
//    body::-webkit-scrollbar {
//        display: none;
//    }
//    #expertsearch-tab-content, .expertsearch
//    {
//        display: none;
//    }
//    h2
//    {
//        font-size: 2.3em;
//        line-height: 1em;
//    }
//    #welcome_view,
//    #detail-view,
//    #main_view,
//    #search-result-view
//    {
//        all: unset;
//        display: block;
//        position: relative;
//        width: 100vw;
//    }
//
//    #welcome_view::-webkit-scrollbar,
//    #detail-view::-webkit-scrollbar,
//    #main_view::-webkit-scrollbar,
//    #search-result-view::-webkit-scrollbar,
//    #search-view::-webkit-scrollbar,
//    #person-data::-webkit-scrollbar,
//    {
//        display: none;
//    }
//    .welcome-data
//    {
//        all: unset;
//    }
//    .welcome-data,
//    .detail-data,
//    #search-view,
//    #search-data,
//    #search-result-list,
//    {
//        display: block;
//        position: relative;
//        width: 80%;
//        margin: 0 auto;
//        padding: 32px;
//        overflow: auto;
//    }
//    #search-data,
//    #person-data,
//    #person-content
//    {
//        position: relative;
//        width: 90%;
//        display: block;
//    }
//    #tab-container
//    {
//        all: unset;
//        position: relative;
//        width: 96%;
//        display: block;
//        left: -23px;
//        margin-bottom: 32px;
//    }
//    #content-tabs
//    {
//        all: unset;
//    }
//    .search_clip input
//    {
//        width: 100%;
//    }
//    .search_input
//    {
//        width: 100%;
//    }
//}
//
//// TABLET || PHONE PORTRAIT
//@media only screen and (max-width: 600px) and (max-height: 1200px),
//only screen and (min-width: 601px) and (max-width: 800px) and (max-height: 1200px),
//only screen and (min-width: 701px)  and (max-width: 900px) and (min-height: 900px) and (max-height: 1300px),
//only screen and (min-width: 901px)  and (max-width: 1024px) and (min-height: 1024px) and (max-height: 1300px)
//{
//    #expertsearch-tab-content, .expertsearch
//    {
//        display: none;
//    }
//    html, body
//    {
//        overflow: scroll;
//    }
//    html::-webkit-scrollbar,
//    body::-webkit-scrollbar {
//        display: none;
//    }
//    h2
//    {
//        font-size: 2.3em;
//        line-height: 1em;
//    }
//    #welcome_view,
//    #detail-view,
//    #main_view,
//    #search-result-view
//    {
//        all: unset;
//        display: block;
//        position: relative;
//        width: 100vw;
//    }
//
//    #welcome_view::-webkit-scrollbar,
//    #detail-view::-webkit-scrollbar,
//    #main_view::-webkit-scrollbar,
//    #search-result-view::-webkit-scrollbar,
//    #search-view::-webkit-scrollbar,
//    #person-data::-webkit-scrollbar,
//    {
//        display: none;
//    }
//
//    .welcome-data
//    {
//        all: unset;
//    }
//
//    .welcome-data,
//    .detail-data,
//    #search-view,
//    #search-data,
//    #search-result-list,
//    {
//        display: block;
//        position: relative;
//        width: 80%;
//        margin: 0 auto;
//        padding: 32px;
//        overflow: auto;
//    }
//    #search-data,
//    #person-data,
//    #person-content
//    {
//        position: relative;
//        width: 80%;
//        display: block;
//        left: -8px;
//    }
//    #person-content
//    {
//        top: 0;
//    }
//    #search-data #tab-container
//    {
//        all: unset;
//        position: relative;
//        width: 96%;
//        display: block;
//        left: -23px;
//        margin-bottom: 32px;
//    }
//
//    .search_clip input
//    {
//        width: 100%;
//    }
//    .search_input
//    {
//        width: 100%;
//    }
//    #search-input,
//    .search_clip
//    {
//        width: 70%;
//    }
//    #content-tabs
//    {
//        all: unset;
//    }
//    #person-content #content-tabs
//    {
//        display: block;
//        width: 80%;
//    }
//    .dict-index
//    {
//        position: absolute;
//        width: 1.5rem;
//        height: 80vh;
//        top: 32px;
//        right: 0px;
//    }
//    #portrait-box,
//    #portrait,
//    .portrait
//    {
//        width: 280px;
//        height: 365px;
//        float: none;
//        margin: 0;
//        padding: 0;
//    }
//
//    .tab-content
//    {
//        width: 80%;
//    }
//    #expertsearch-tab-content, .expertsearch
//    {
//        display: none;
//    }
//    #tab-container
//    {
//        display: inline;
//        left: -32px;
//    }
//    #search-result-container
//    {
//        width: 96vw;
//    }
//    #tab-container .tab-button:not(:first-of-type)
//    {
//        border-left: none;
//    }
//    #person-content,
//    #person-content #content-tabs
//    {
//        width: 100%;
//    }
//    #person-content video
//    {
//        width: 320px;
//    }
//    #person-data #tab-container
//    {
//        all:unset;
//    }
//    #person-data #tab-container .link-box
//    {
//        all:unset;
//        margin: 12px;
//    }
//    #person-data #tab-container
//    {
//        display: flex;
//        flex-direction: row-reverse;
//        position: fixed;
//        right: 2.5rem;
//        top: 2rem;
//        width: 100vh;
//        height: 56px;
//        float: left;
//        transform: rotate(270deg);
//        -webkit-transform: rotate(270deg);
//        -ms-transform: rotate(270deg);
//        transform-origin: left bottom 0;
//        -webkit-transform-origin: right top 0;
//        text-align: center;
//        -ms-transform-origin: left bottom 0;
//        align-self: center;
//    }
//}
#footer-nav-left .link-box-bottom .brand-link-magenta,
.brand-link-magenta
{
    color: #ec008b;
}
.option-greyscale .result-property-label
{
    color: #000;
}
.option-greyscale.theme-color-dark .result-property-label,
{
    color: #fff;
}
.option-greyscale #footer-nav-left .link-box-bottom .brand-link-magenta,
.option-greyscale .brand-link-magenta
{
    color: #000;
}

.option-greyscale.theme-color-dark #footer-nav-left .link-box-bottom .brand-link-magenta,
.option-greyscale .brand-link-magenta
{
    color: #fff;
}