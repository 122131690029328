.option-greyscale #start_view {
    color: #fff;
}

.theme-color-light{
    background-color: #fff;
    color: #000;
}

.theme-color-light a,
.theme-color-light button
{
    color: #000;
    fill: rgba(0,0,0,0.7);
}

.theme-color-light #passepartout a,
.theme-color-light #passepartout button
{
    color: rgba(0,0,0,0.7);
    fill: rgba(0,0,0,0.7);
}

.theme-color-light h3,
.theme-color-light .brand-link{
    color: #000;
}

.theme-color-light .brand-link-magenta
{
    color: #ec008b;
}

.theme-color-light .option-highlight-all-the-links .brand-link-magenta,
.option-highlight-all-the-links .theme-color-light .brand-link-magenta
{
    color: #000 !important;
}

.theme-color-light a:hover,
.theme-color-light button:hover
{
    color: rgba(0,0,0, 1);
    text-shadow: 1px 0 0 rgba(0,0,0,1);
}

.theme-color-light .active
{
    color: rgba(0,0,0, 1) !important;
    text-shadow: 1px 0 0 rgba(0,0,0,1);
}

.theme-color-light #search-input-fields input,
.theme-color-light #search-input input,
.theme-color-light .input
{
    border: 1px solid #000;
    color: #000;
}

.theme-color-light ::-webkit-input-placeholder,
.theme-color-light ::-moz-placeholder,
.theme-color-light .search_clip ::-webkit-input-placeholder,
.theme-color-light .search_clip input::-moz-placeholder{
    color:#000;
    opacity: 1
}

.theme-color-light ::-moz-placeholder,
.theme-color-light .search_clip ::-moz-placeholder{
    color:#000;
    opacity: 1
}

.theme-color-light ::-ms-placeholder,
.theme-color-light .search_clip ::-ms-placeholder{
    color:#000;
}

.theme-color-light ::placeholder,
.theme-color-light .search_clip ::placeholder{
    color:#000;
}


.theme-color-light input:focus, textarea:focus, select:focus {
    outline-offset: 0px;
}

.theme-color-light #intro-headline,
.theme-color-light #start_view h2 {
    color: #ec008b;
}

.theme-color-light.option-greyscale #intro-headline,
.theme-color-light.option-greyscale #start_view h2 {
    color: #fff;
}

.theme-color-light #left-sidebar,
.theme-color-light #right-sidebar,
.theme-color-light #topbar,
.theme-color-light #footer{
    background-color: #fff;
}

.theme-color-light .search_clip input {
    background-color: rgba(0, 0, 0, 0);
}

/******* DARK THEME *****/
.theme-color-dark,
.theme-color-dark #main_view{
    background-color: #000;
    color: #fff;
}

.theme-color-dark #intro_view h2{
    color: #ec008b;
}

.theme-color-dark.option-greyscale #intro_view h2{
    color: #fff;
}

.theme-color-dark a,
.theme-color-dark button{
    color: #fff;
    fill: #fff;
}

.theme-color-dark .brand-link-magenta{
    color: #ec008b !important;
}
.theme-color-dark .mdc-list-item:hover .mdc-list-item__primary-text,
.theme-color-dark a:hover,
.theme-color-dark button:hover {
    color: rgba(255, 255, 255, 1) !important;
    text-shadow: 1px 0 0 rgba(255, 255, 255, 1) !important;
}
.theme-color-dark.option-highlight-all-the-links button:hover,
.theme-color-dark.option-highlight-all-the-links button:hover .link-box,
.theme-color-dark.option-highlight-all-the-links button:hover .link-box .tab
{
    color: rgba(0,0,0, 1);
    text-shadow: 1px 0 0 rgba(0,0,0,1);
}

.theme-color-dark input {
    border: 1px solid #fff;
    color: #000000;
}

.theme-color-dark .search_clip input {
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
}

.theme-color-dark ::placeholder,
.theme-color-dark .search_clip ::placeholder
{
    color: #fff;
}

.theme-color-dark .search-btn
{
    border: solid 1px #ccc;
    background: #ccc;
    color: #000;
}

.theme-color-dark #left-sidebar,
.theme-color-dark #right-sidebar,
.theme-color-dark #topbar,
.theme-color-dark #footer{
    background-color: #000;
}

.theme-color-dark a.active,
.theme-color-dark button.active{
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold !important;

}

h3 a:hover, a:hover h3,
h4 a:hover, a:hover h4 {
    text-shadow: none !important;
}


