.link-box.highlight a,
.link-box.highlight button
{
    color: #000;
    fill: #000 !important;
}
.default-pointer
{
    cursor: pointer;
}
.custom-cursor-black
{
    cursor: url('../images/cursor-black-fill-42.cur'), url('../images/cursor-black-fill-42.png'), auto !important;
}
.custom-cursor-white
{
    cursor: url('../images/cursor-white-42.cur'), url('../images/cursor-white-42.png'), auto !important;
}
.custom-pointer-black
{
    cursor: url('../images/cursor-pointer-black-42.png') 14 0, auto !important;
}
.custom-pointer-white
{
    cursor: url('../images/cursor-pointer-white-42.png') 14 0, auto !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
{
    /* IE10+ CSS */
    .custom-cursor-black
    {
        cursor: url('../images/cursor-black-fill-42.cur'), auto !important;
    }
    .custom-cursor-white
    {
        cursor: url('../images/cursor-white-42.cur'), auto !important;
    }
    .custom-pointer-black
    {
        cursor: url('../images/cursor-pointer-black-42.cur'), auto !important;
    }
    .custom-pointer-white
    {
        cursor: url('../images/cursor-pointer-white-42.cur'), auto !important;
    }
}
@supports (-ms-accelerator:true)
{
    /* IE Edge 12+ CSS */
    .custom-cursor-black
    {
        cursor: url('../images/cursor-black-fill-42.cur'), auto !important;
    }
    .custom-cursor-white
    {
        cursor: url('../images/cursor-white-42.cur'), auto !important;
    }
    .custom-pointer-black
    {
        cursor: url('../images/cursor-pointer-black-42.cur'), auto !important;
    }
    .custom-pointer-white
    {
        cursor: url('../images/cursor-pointer-white-42.cur'), auto !important;
    }
}
@supports (-ms-ime-align:auto)
{
    /* IE Edge 16+ CSS */
    .custom-cursor-black
    {
        cursor: url('../images/cursor-black-fill-42.cur'), auto !important;
    }
    .custom-cursor-white
    {
        cursor: url('../images/cursor-white-42.cur'), auto !important;
    }
    .custom-pointer-black
    {
        cursor: url('../images/cursor-pointer-black-42.cur'), auto !important;
    }
    .custom-pointer-white
    {
        cursor: url('../images/cursor-pointer-white-42.cur'), auto !important;
    }
}

/** HIGHLIGHTS COLORS GREYSCALE***/

.theme-color-dark.option-greyscale #start_view h2,
.theme-color-light.option-greyscale #start_view h2,
.theme-color-light.option-greyscale .col:hover .col-footer-label,
.theme-color-dark.option-greyscale .col:hover .col-footer-label
{
    color: #fff;
}
.theme-color-dark .accessibility-settings
{
    background: rgba(255,255,255, 0.8);
}
.theme-color-light .accessibility-settings
{
    background: rgba(18,18,18, 0.8) !important;
}
.theme-color-light .accessibility-settings .cancel-button path
{
    fill: #fff;
}
.theme-color-light.option-highlight-all-the-links .accessibility-settings .cancel-button path
{
    fill: #000;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links .accessibility-settings .cancel-button path
{
    fill: #fff;
}
.theme-color-dark .square
{
    background: rgba(18,18,18, 1);
}
.theme-color-light .square
{
    background: rgba(255,255,255, 1);
}
.theme-color-light .square:hover
{
    background-color: rgba(18, 18, 18, 0.7);
    color: #fff;
}
.theme-color-light .square:hover path
{
    fill: #fff;
}
.theme-color-dark .square:hover
{
    background-color: rgba(18, 18, 18, 0.7);
    color: #fff;
}
.theme-color-dark .square-icon svg path
{
    fill: #fff;
}
.theme-color-dark.option-highlight-all-the-links .link-box,
.theme-color-light.option-highlight-all-the-links .link-box,
.theme-color-light.option-highlight-all-the-links .brand-link,
.theme-color-dark.option-highlight-all-the-links a,
.theme-color-light.option-highlight-all-the-links a,
.theme-color-dark.option-highlight-all-the-links button,
.theme-color-light.option-highlight-all-the-links button
{
    background-color: #ec008b;
}
.theme-color-light.option-highlight-all-the-links .link-box,
.theme-color-light.option-highlight-all-the-links .brand-link,
.theme-color-light.option-highlight-all-the-links a,
.theme-color-light.option-highlight-all-the-links button
{
    color: #000;
    background-color: #ec008b;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links .link-box,
.theme-color-light.option-greyscale.option-highlight-all-the-links .brand-link,
.theme-color-light.option-greyscale.option-highlight-all-the-links a,
.theme-color-light.option-greyscale.option-highlight-all-the-links button,
.theme-color-light.option-greyscale.option-highlight-all-the-links a h3
{
    color: #fff !important;
    background-color: #000;
}
.theme-color-dark.option-highlight-all-the-links .link-box,
.theme-color-dark.option-highlight-all-the-links .brand-link,
.theme-color-dark.option-highlight-all-the-links a,
.theme-color-dark.option-highlight-all-the-links button
{
    color: #000 !important;
}
.theme-color-dark.option-highlight-all-the-links.option-greyscale .link-box,
.theme-color-dark.option-highlight-all-the-links.option-greyscale .brand-link,
.theme-color-dark.option-highlight-all-the-links.option-greyscale a,
.theme-color-dark.option-highlight-all-the-links.option-greyscale button,
.theme-color-dark.option-highlight-all-the-links.option-greyscale button.mdc-button mat-icon
{
    color: #000 !important;
}
.theme-color-light.option-greyscale .brand-link-magenta
{
    color: #000 !important;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links .path-bg
{
    color: #000 !important;
}
.theme-color-light.option-highlight-all-the-links.option-greyscale .path-ico
{
    fill: #fff !important;
}
.theme-color-light.option-highlight-all-the-links.option-greyscale circle
{
    fill: #fff !important;
}
.theme-color-dark.option-highlight-all-the-links .path-bg
{
    fill: #ec008b !important;
}
.theme-color-dark.option-highlight-all-the-links .path-ico
{
    fill: #000;
}
.theme-color-dark.option-highlight-all-the-links circle
{
    fill: #000;
}
.theme-color-dark.option-highlight-all-the-links.option-greyscale .path-bg
{
    fill: #fff !important;
}
.theme-color-dark.option-highlight-all-the-links.option-greyscale .path-ico
{
    fill: #000;
}
.theme-color-dark.option-highlight-all-the-links.option-greyscale circle
{
    fill: #000;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links .link-box,
.theme-color-dark.option-greyscale.option-highlight-all-the-links a,
.theme-color-dark.option-greyscale.option-highlight-all-the-links button
{
    background-color: #fff;
    color: #000;
}
.theme-color-dark.option-highlight-all-the-links .link-box .svg-btn
{
    background-color: #ec008b;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links .svg-btn
{
    background-color: #fff;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links .link-box path
{
    fill: #000;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links .active-lang-button
{
    background-color: #000;
    color: #000 !important;
    font-weight: bold;
}
#right-sidebar a, #left-sidebar a
{
    height: 1.5em;
    padding: 0 10px;
}
.theme-color-dark.option-highlight-all-the-links .active-lang-button a
{
    color: initial;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links .active-lang-button a
{
    color: #000 !important;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links .link-box
{
    background-color: #000;
    color: #fff;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links #topbar .active,
.theme-color-light.option-greyscale.option-highlight-all-the-links .tab-container .active
{
    color: #fff;
    text-decoration: underline;
}
.theme-color-light.option-greyscale .active-lang-button a
{
    text-decoration: underline !important;
    color: #000 !important;
}
.theme-color-light.option-greyscale.option-highlight-all-the-links .active-lang-button a
{
     text-decoration: underline !important;
     color: #fff !important;
}
.theme-color-dark.option-greyscale .active-lang-button a
{
    text-decoration: underline !important;
    color: #fff !important;
}
.theme-color-dark.option-greyscale .brand-link-magenta,
.theme-color-dark.option-greyscale .brand-link
{
    color: #fff !important;
}
.theme-color-dark.option-greyscale a.active
{
    text-decoration: underline !important;
    color: #fff !important;
}
.theme-color-dark.option-greyscale a:hover,
.theme-color-dark.option-greyscale .brand-link-magenta a:hover
.theme-color-dark button:hover
{
    text-decoration: underline !important;
}
.theme-color-light.option-greyscale #intro_view a:hover,
.theme-color-dark.option-greyscale #intro_view a:hover
{
    text-decoration: none !important;
}
.theme-color-dark #branding a:hover
{
    color: #fff;
}
.theme-color-dark.option-greyscale.option-highlight-all-the-links a.active
{
    text-decoration: underline !important;
    color: #000 !important;
}
.theme-color-dark #intro_view a:hover,
.theme-color-dark #intro_view a:hover h1
{
    color: rgba(255,255,255, 1);
    text-shadow: none;
}
.option-greyscale #intro_view a,
.option-greyscale #intro_view a h1
{
    color: rgba(255,255,255, 1) !important;
}

.theme-color-light.option-greyscale .colored-label
{
    color: #000;
}
.theme-color-dark.option-greyscale .colored-label
{
    color: #fff;
}